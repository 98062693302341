import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import schedule from "node-schedule";
import { useDispatch } from "react-redux";
import { addSites } from "../../redux/slice/sitesSlice";
import { useSelector } from "react-redux";
import { addTests } from "../../redux/slice/testsSlice";
import { addTreatments } from "../../redux/slice/treatmentsSlice";
import { addSupplements } from "../../redux/slice/supplementsSlice";
import { getAllSites, getAllSupplements, getServicesByType } from "../../apis";

const InitialAPICalls = () => {
  const dispatch = useDispatch();
  const allSites: any = useSelector<SitesState>((state) => state.sites);

  const getSites = async () => {
    const [response, error] = await getAllSites();
    if (response) {
      dispatch(addSites(response));
    } else {
      console.log(error);
    }
  };

  const getServices = async (type: string) => {
    const [response, error] = await getServicesByType(type);
    if (response) {
      return response;
    } else {
    }
  };

  const getAllTests = async () => {
    dispatch(addTests(await getServices("Test")));
  };

  const getAllTreatments = async () => {
    dispatch(addTreatments(await getServices("Treatment")));
  };

  const getSupplements = async () => {
    const [response, error] = await getAllSupplements();
    if (response) dispatch(addSupplements(response));
  };

  useEffect(() => {
    getSites();
    getAllTests();
    getAllTreatments();
    getSupplements();
  }, []);

  return <></>;
};

export default InitialAPICalls;
