import Cookies from "js-cookie";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

interface RequireAuthProps {
  allowedRoles: string[];
}

const RequireAuth: React.FC<RequireAuthProps> = ({ allowedRoles }) => {
  const location = useLocation();
  const authCookie = Cookies.get("authToken");
  const user: any = useSelector<UserState>((state) => state.user);
  const roles = user.user.roles;

  if (!authCookie) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const hasAllowedRoles = roles?.some((role: string) =>
    allowedRoles?.includes(role)
  );

  if (hasAllowedRoles) {
    return <Outlet />;
  }

  return <Navigate to="/unauthorized" state={{ from: location }} replace />;
};

export default RequireAuth;
