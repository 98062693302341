import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/slice/authSlice";
import { BRAND_COLOR_LIGHT } from "../../../constants/BrandConstants";
import { Typography } from "@mui/material";
type User = {
  firstName: string;
  lastName: string;
  image?: string;
};

type ProfileAvatarProps = {
  user: User;
};

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({ user }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    handleClose();
    dispatch(logout());
    // navigate("/login");
    window.location.reload();
  };
  const renderAvatarContent = () => {
    if (user.image) {
      return <Avatar alt={user.firstName} src={user.image} />;
    } else {
      const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;

      return (
        <Avatar sx={{ bgcolor: BRAND_COLOR_LIGHT, fontSize: 16 }}>
          <b>{initials}</b>
        </Avatar>
      );
    }
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ mr: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 30, height: 30 }}>
              {renderAvatarContent()}
            </Avatar>
            <Typography paddingLeft={2} sx={{ fontWeight: 500 }}>
              {user.firstName} {user.lastName}
            </Typography>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 30,
              height: 30,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem
          onClick={() => {
            handleClose();
            navigate("/profile");
          }}>
          <Avatar sx={{ width: 32, height: 32 }}>
            {renderAvatarContent()}
          </Avatar>
          <span className="ms-2"> Profile</span>
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose}>
          <Avatar /> <span className="ms-2">My account</span>
        </MenuItem> */}
        {/* <Divider sx={{ border: "1px solid black", m: 2 }} />  */}
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem> */}
        {/* <MenuItem
          onClick={() => {
            handleClose();
            navigate("/profile");
          }}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <span> Settings</span>
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <span> Logout</span>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ProfileAvatar;
